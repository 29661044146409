import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const AddNewNurse = ({ show, onHide, editingNurse, onAddOrUpdateNurse }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [details, setDetails] = useState({
    userrole: "",
    lastName: "",
    firstName: "",
    middleName: "",
    goByName: "",
    cellPhone: "",
    workPhoneNumber: "",
    workEmail: "",
    personalEmail: "",
    fte: "",
  });

  useEffect(() => {
    if (editingNurse) {
      // setName(editingNurse.name);
      // setEmail(editingNurse.email);
      setDetails({
        userrole: editingNurse.userrole,
        lastName: editingNurse.lastName,
        firstName: editingNurse.firstName,
        middleName: editingNurse.middleName,
        goByName: editingNurse.goByName,
        cellPhone: editingNurse.cellPhone,
        workPhoneNumber: editingNurse.workPhoneNumber,
        workEmail: editingNurse.workEmail,
        personalEmail: editingNurse.personalEmail,
        fte: editingNurse.fte,
      });
    } else {
      // setName("");
      // setEmail("");
      resetDetails();
    }
    // setMessage("");
    resetDetails();
  }, [editingNurse]);

  const handleClose = () => {
    onHide();
    setIsSubmited(false);
    resetDetails();
    setMessage("");
  };

  const resetDetails = () => {
    setDetails({
      userrole: "",
      lastName: "",
      firstName: "",
      middleName: "",
      goByName: "",
      cellPhone: "",
      workPhoneNumber: "",
      workEmail: "",
      personalEmail: "",
      fte: "",
    });
  };

  const getUserId = (name) => {
    const firstLetter = name.charAt(0).toUpperCase();
    const randomNumbers = Math.floor(100000 + Math.random() * 900000);
    return `${firstLetter}${randomNumbers}`;
  };

  const handleSubmit = async () => {
    setLoading(true);
    setMessage("");
    setIsSubmited(true);

    try {
      // if (!name || !email) {
      //   throw new Error("Please fill in all fields.");
      // }
      if (
        !details.cellPhone ||
        !details.firstName ||
        !details.goByName ||
        !details.middleName ||
        !details.lastName ||
        !details.personalEmail ||
        !details.workEmail ||
        !details.userrole ||
        !details.workPhoneNumber ||
        !details.fte
      ) {
        throw new Error("Please fill in all fields.");
      }

      // Check for duplicate emails, excluding the current editing nurse's email
      const nurseQuery = query(
        collection(db, "nurses"),
        where("personalEmail", "==", details.personalEmail)
      );
      const querySnapshot = await getDocs(nurseQuery);
      const isDuplicateEmail = querySnapshot.docs.some((doc) =>
        editingNurse ? doc.id !== editingNurse.id : true
      );

      if (isDuplicateEmail) {
        throw new Error("Nurse with this email already exists.");
      }

      if (editingNurse) {
        // Update existing nurse, including email
        await updateDoc(doc(db, "nurses", editingNurse.id), details);
        setMessage("Nurse updated successfully!");
      } else {
        // Add new nurse
        const nurseData = {
          userId: getUserId(details.lastName),
          userrole: details.userrole,
          firstName: details.firstName,
          lastName: details.lastName,
          middleName: details.middleName,
          goByName: details.goByName,
          cellPhone: details.cellPhone,
          workPhoneNumber: details.workPhoneNumber,
          workEmail: details.workEmail,
          personalEmail: details.personalEmail,
          fte: details.fte,
          chargeRn: false,
        };

        // Add the document and get the reference
        const docRef = await addDoc(collection(db, "nurses"), nurseData);

        // Update the document with its own ID
        await updateDoc(docRef, { id: docRef.id });

        setMessage("Nurse added successfully!");
        handleClose();
        resetDetails();
        onAddOrUpdateNurse();
        setIsSubmited(false);
      }
      setMessage("");
    } catch (error) {
      console.error("Error adding/updating nurse: ", error);
      setMessage(
        error.message || "Failed to add/update nurse. Please try again."
      );
    } finally {
      setLoading(false);
      // if (message.includes("successfully")) {
      //   // Reset form and close modal after successful add/update
      //   resetDetails();
      //   onAddOrUpdateNurse();
      //   // handleClose();
      //   setIsSubmited(false);
      // }
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {editingNurse ? "Edit Nurse" : "Add New Nurse"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && (
          <p
            className={
              message.includes("successfully")
                ? "alert alert-success"
                : "alert alert-danger"
            }
            role="alert"
          >
            {message}
          </p>
        )}

        <div className="row">
          <div className="mb-3 col-6">
            <label className="form-label">User Role (Many)</label>
            <input
              value={details.userrole}
              onChange={(e) =>
                setDetails({ ...details, userrole: e.target.value })
              }
              type="text"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.userrole && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Last Name</label>
            <input
              value={details.lastName}
              onChange={(e) =>
                setDetails({ ...details, lastName: e.target.value })
              }
              // value={email}
              // onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.lastName && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">First Name</label>
            <input
              value={details.firstName}
              onChange={(e) =>
                setDetails({ ...details, firstName: e.target.value })
              }
              type="text"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.firstName && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Middle Name</label>
            <input
              value={details.middleName}
              onChange={(e) =>
                setDetails({ ...details, middleName: e.target.value })
              }
              type="text"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.middleName && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Go By Name</label>
            <input
              value={details.goByName}
              onChange={(e) =>
                setDetails({ ...details, goByName: e.target.value })
              }
              type="text"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.goByName && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Cell Phone</label>
            <input
              value={details.cellPhone}
              onChange={(e) =>
                setDetails({ ...details, cellPhone: e.target.value })
              }
              type="number"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.cellPhone && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Work Phone Number</label>
            <input
              value={details.workPhoneNumber}
              onChange={(e) =>
                setDetails({ ...details, workPhoneNumber: e.target.value })
              }
              type="number"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.workPhoneNumber && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Work Email</label>
            <input
              value={details.workEmail}
              onChange={(e) =>
                setDetails({ ...details, workEmail: e.target.value })
              }
              type="email"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.workEmail && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Personal Email</label>
            <input
              value={details.personalEmail}
              onChange={(e) =>
                setDetails({ ...details, personalEmail: e.target.value })
              }
              type="email"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.personalEmail && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">FTE</label>
            <input
              value={details.fte}
              onChange={(e) => setDetails({ ...details, fte: e.target.value })}
              type="text"
              className="form-control"
              disabled={loading}
            />
            {/* {!details.fte && isSubmited && (
              <p className="text-danger m-0 mt-1 fs-sm">Please Enter Value</p>
            )} */}
          </div>
        </div>
        <div className="d-grid mb-2">
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading
              ? "Processing..."
              : editingNurse
              ? "Update Nurse"
              : "Add Nurse"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewNurse;
