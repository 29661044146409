import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/authentication/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminDashboard from "./components/admin/AdminDashboard";
import Profile from "./components/authentication/Profile";
import InchargeDashboard from "./components/incharge/InchargeDashboard";
import LdDashboard from "./components/lddashboard/LdDashboard";

function App() {
  return (
    <Router basename={process.env.REACT_APP_HOME_PAGE}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/incharge-dashboard" element={<InchargeDashboard />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/ld-dashboard" element={<LdDashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
