import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  getDocs,
  collection,
  doc,
  updateDoc,
  getDoc,
  onSnapshot,
  addDoc,
} from "firebase/firestore";

const LdRoomAssigned = ({ setShiftNurses }) => {
  const [textAreaValue, setTextAreaValue] = useState("");
  const [documentId, setDocumentId] = useState("Pjj4SHVJstA0UD3NUyzo");
  const handleBlur = async () => {
    if (documentId) {
      // If documentId exists, update the existing document
      await updateFirestoreDocument(documentId, textAreaValue);
    } else {
      // If documentId doesn't exist, create a new document
      const id = await saveToFirestore(textAreaValue);
      setDocumentId(id); // Save the document ID for future updates
    }
  };

  const saveToFirestore = async (text) => {
    try {
      const docRef = await addDoc(collection(db, "lduserinput"), {
        Data: text,
        timestamp: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
      return docRef.id; // Return the document ID
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const updateFirestoreDocument = async (id, text) => {
    try {
      const docRef = doc(db, "lduserinput", id);
      await updateDoc(docRef, {
        Data: text,
        timestamp: new Date(), // Optionally update the timestamp
      });
      console.log("Document updated with ID: ", id);
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  };
  const handleChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  const fetchDocument = async (id) => {
    try {
      const docRef = doc(db, "lduserinput", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setTextAreaValue(docSnap.data().Data); // Set the textarea value to the retrieved data
      } else {
        console.log("No such document!");
      }
    } catch (e) {
      console.error("Error fetching document: ", e);
    }
  };

  /* const column1 = [
    "Tot Beds",
    "Beds Full",
    "Bed Avail",
    "How Full%",
    "Laboring Pts",
    "Triage Pts",
    "Ind - On Hold",
    "PushPaus/TOLAC",
  ]; */

  // const column2 = ["-", "-", "-", "-", "-", "-", "-", ""];

  const [bedsData, setBedsData] = useState([]);
  const [nurseOptions, setNurseOptions] = useState([]);
  const [acuityOptions] = useState([
    { label: "1:1", value: 0.89 },
    { label: "1:2", value: 0.44 },
    { label: "1:3", value: 0.3 },
    { label: "1:4", value: 0.23 },
    { label: "1:5", value: 0.18 },
  ]);

  useEffect(() => {
    const bedsCollection = collection(db, "ldbeds");
    const unsubscribeBeds = onSnapshot(bedsCollection, (snapshot) => {
      const bedsDataFromFirestore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      bedsDataFromFirestore.sort((a, b) =>
        a.bedNumber.localeCompare(b.bedNumber)
      );
      setBedsData(bedsDataFromFirestore);
    });

    // Fetch nurses data
    const shiftDocRef = doc(db, "ldNurseShift", "9HGpGf4u8muT31YlcPw5");
    const unsubscribeNurses = onSnapshot(shiftDocRef, (snapshot) => {
      const data = snapshot.data();
      setNurseOptions(data.nurses);
    });

    // Clean up subscriptions on component unmount
    return () => {
      unsubscribeBeds();
      unsubscribeNurses();
    };
  }, []);

  // const handleNurseChange = async (bedId, newNurseId) => {
  //   try {
  //     // Step 1: Update the nurse assignment for the bed in Firestore
  //     await updateDoc(doc(db, "ldbeds", bedId), { nurseId: newNurseId });

  //     // Fetch the latest beds data after the update to ensure accuracy
  //     const bedsSnapshot = await getDocs(collection(db, "ldbeds"));
  //     const latestBedsData = bedsSnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     // Prepare to recalculate beds and acuity for nurses
  //     const nurseCalculations = {}; // Object to hold recalculated values

  //     // Initialize all nurses in nurseCalculations to ensure we correctly handle nurses with 0 beds or acuity
  //     nurseOptions.forEach((nurse) => {
  //       nurseCalculations[nurse.id] = { totalBeds: 0, totalAcuity: 0 };
  //     });

  //     // Recalculate total beds and acuity for each nurse based on the updated beds data
  //     latestBedsData.forEach((bed) => {
  //       const nurseId = bed.nurseId;
  //       if (nurseId && nurseCalculations[nurseId] !== undefined) {
  //         console.log(bed.acuity);
  //         nurseCalculations[nurseId].totalBeds += 1;
  //         // Assume bed.acuity is a value that directly corresponds to one of the acuityOptions values
  //         const acuityValue = parseFloat(
  //           bed.acuity ? (bed.acuity == 0 ? 0.89 : bed.acuity) : 0.89
  //         );
  //         nurseCalculations[nurseId].totalAcuity += acuityValue;
  //       }
  //     });

  //     // Update the ldNurseShift document in Firestore with recalculated values
  //     const updatedNurses = nurseOptions.map((nurse) => ({
  //       ...nurse,
  //       totalBeds: nurseCalculations[nurse.id].totalBeds,
  //       // Ensure totalAcuity is rounded or formatted as needed
  //       /*  totalAcuity: parseFloat(
  //         nurseCalculations[nurse.id].totalAcuity.toFixed(2)
  //       ), */
  //       totalAcuity:
  //         nurse?.id === newNurseId && Number(nurse?.totalAcuity) > 0
  //           ? Number(nurse?.totalAcuity) + 0.89
  //           : Number(nurseCalculations[nurse.id].totalAcuity),
  //     }));

  //     // Update Firestore
  //     const shiftDocRef = doc(db, "ldNurseShift", "9HGpGf4u8muT31YlcPw5");
  //     await updateDoc(shiftDocRef, { nurses: updatedNurses });

  //     // Update local state
  //     setBedsData(latestBedsData);
  //     setNurseOptions(updatedNurses);
  //   } catch (error) {
  //     console.error("Error updating nurse assignment:", error);
  //   }
  // };
  const handleNurseChange = async (bedId, newNurseId) => {
    try {
      // Fetch the current bed data
      const bedDocRef = doc(db, "ldbeds", bedId);
      const bedDocSnap = await getDoc(bedDocRef);
      const currentBedData = bedDocSnap.data();
      const oldNurseId = currentBedData.nurseId;

      // Update the nurse assignment for the bed in Firestore
      await updateDoc(bedDocRef, { nurseId: newNurseId });

      // Fetch the latest nurse shift data
      const shiftDocRef = doc(db, "ldNurseShift", "9HGpGf4u8muT31YlcPw5");
      const shiftDocSnap = await getDoc(shiftDocRef);
      const shiftData = shiftDocSnap.data();
      let updatedNurses = shiftData.nurses;

      // Function to adjust nurse acuity
      const adjustNurseAcuity = (nurseId, adjustment) => {
        const nurseIndex = updatedNurses.findIndex(
          (nurse) => nurse.id === nurseId
        );
        if (nurseIndex !== -1) {
          const currentAcuity =
            parseFloat(updatedNurses[nurseIndex].totalAcuity) || 0;
          const newAcuity = Math.max(0, currentAcuity + adjustment);
          updatedNurses[nurseIndex].totalAcuity = newAcuity.toFixed(2);
        }
      };

      // Adjust acuity for the old nurse (if there was one)
      if (oldNurseId) {
        adjustNurseAcuity(oldNurseId, -0.89);
      }
      
      // Adjust acuity for the new nurse (if one was selected)
      if (newNurseId) {
        adjustNurseAcuity(newNurseId, 0.89);
      }

      // Update Firestore with the new nurse data
      await updateDoc(shiftDocRef, { nurses: updatedNurses });

      // Update local state
      setNurseOptions(updatedNurses);

      // Fetch and update beds data
      const bedsSnapshot = await getDocs(collection(db, "ldbeds"));
      const latestBedsData = bedsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBedsData(latestBedsData);
    } catch (error) {
      console.error("Error updating nurse assignment:", error);
    }
  };

  const handleAcuityChange = async (bedId, nurseId, value) => {
    try {
      await updateDoc(doc(db, "ldbeds", bedId), { acuity: value });

      // Update local state
      const updatedBedsData = bedsData.map((bed) =>
        bed.id === bedId ? { ...bed, acuity: value } : bed
      );
      setBedsData(updatedBedsData);

      // Calculate the total acuity for the nurse
      let totalAcuity = 0;
      updatedBedsData.forEach((bed) => {
        if (bed.nurseId === nurseId) {
          const acuityValue = parseFloat(
            acuityOptions.find(
              (option) => option.value === parseFloat(bed.acuity)
            )?.value || 0
          );
          totalAcuity += acuityValue;
        }
      });

      // Round total acuity to 2 decimal places
      totalAcuity = totalAcuity.toFixed(2);

      // Update the nurse shift document
      const shiftDocRef = doc(db, "ldNurseShift", "9HGpGf4u8muT31YlcPw5");
      const shiftDocSnapshot = await getDoc(shiftDocRef);
      if (shiftDocSnapshot.exists()) {
        const shiftData = shiftDocSnapshot.data();
        const updatedNurses = shiftData.nurses.map((nurse) => {
          if (nurse.id === nurseId) {
            return { ...nurse, totalAcuity };
          } else {
            return nurse;
          }
        });
        await updateDoc(shiftDocRef, { nurses: updatedNurses });
      }
    } catch (error) {
      console.error("Error updating acuity:", error);
    }
  };

  const handleNotesChange = async (bedId, value) => {
    try {
      await updateDoc(doc(db, "ldbeds", bedId), { notes: value });
      // Update local state
      const updatedBedsData = bedsData.map((bed) =>
        bed.id === bedId ? { ...bed, notes: value } : bed
      );
      setBedsData(updatedBedsData);
    } catch (error) {
      console.error("Error updating notes:", error);
    }
  };

  const handleEDDChange = async (bedId, value) => {
    try {
      await updateDoc(doc(db, "ldbeds", bedId), { edd: value });
      // Update local state
      const updatedBedsData = bedsData.map((bed) =>
        bed.id === bedId ? { ...bed, edd: value } : bed
      );
      setBedsData(updatedBedsData);
    } catch (error) {
      console.error("Error updating EDD:", error);
    }
  };

  // Function to filter beds based on bed number range
  const filterBedsByRange = (start, end) => {
    const extractNumber = (bedNumber) => parseInt(bedNumber.match(/\d+/)[0]);

    const data = bedsData
      .filter((bed) => {
        const bedNum = extractNumber(bed.bedNumber);
        return (
          bed.bedNumber.startsWith(start.slice(0, -1)) &&
          bedNum >= extractNumber(start) &&
          bedNum <= extractNumber(end)
        );
      })
      .sort((a, b) => extractNumber(a.bedNumber) - extractNumber(b.bedNumber));
    return data;
  };

  useEffect(() => {
    if (documentId) {
      fetchDocument(documentId); // Fetch the document from Firestore
    }
  }, []);

  return (
    <div>
      {/* 2401 -2407 */}
      <div className="table-responsive mb-4 ">
        <table
          className="table table-hover table-sm table-bordered table-primary"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th className="text-center" colSpan="11">
                Triage
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th></th>
              {filterBedsByRange("ob1", "ob10").map((bed, index) => (
                <td key={`bed-${bed.id}`} className=" text-center bedhead">
                  {bed.bedNumber.toUpperCase()}
                </td>
              ))}
            </tr>

            <tr>
              <th>Nurse</th>
              {filterBedsByRange("ob1", "ob10").map((bed) => (
                <td key={`bed-${bed.id}`}>
                  <select
                    className="bedbody input-field"
                    value={bed.nurseId}
                    onChange={(e) => handleNurseChange(bed.id, e.target.value)}
                  >
                    <option value="">Select Nurse</option>
                    {nurseOptions.map((nurse) => (
                      <option key={nurse.id} value={nurse.id}>
                        {nurse.name}
                      </option>
                    ))}
                  </select>
                </td>
              ))}
            </tr>
            <tr>
              <th>Acuity</th>
              {filterBedsByRange("ob1", "ob10").map((bed) => (
                <td key={`bed-${bed.id}`}>
                  <select
                    className="bedbody input-field"
                    value={bed.acuity}
                    onChange={(e) =>
                      handleAcuityChange(bed.id, bed.nurseId, e.target.value)
                    }
                  >
                    {acuityOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </td>
              ))}
            </tr>
            <tr>
              <th>Notes</th>
              {filterBedsByRange("ob1", "ob10").map((bed) => (
                <td key={`bed-${bed.id}`}>
                  <input
                    className="bedbody input-field"
                    type="text"
                    value={bed.notes}
                    onChange={(e) => handleNotesChange(bed.id, e.target.value)}
                  />
                </td>
              ))}
            </tr>
            {/* <tr>
              <th>EDD</th>
              {filterBedsByRange("ob1", "ob10").map((bed) => (
                <td key={`bed-${bed.id}`}>
                  <input
                    className="bedbody input-field"
                    type="date"
                    value={bed.edd}
                    onChange={(e) => handleEDDChange(bed.id, e.target.value)}
                  />
                </td>
              ))}
            </tr> */}
          </tbody>
        </table>
      </div>

      {/* 2501 -2510 */}

      <div className="table-responsive mb-4">
        <table
          className="table table-hover table-sm table-bordered table-danger"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th className="text-center" colSpan="11">
                Labor and Delivery
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th></th>
              {filterBedsByRange("ld1", "ld10").map((bed, index) => (
                <th key={`bed-${bed.id}`} className="bedhead text-center">
                  {bed.bedNumber.toUpperCase()}
                </th>
              ))}
            </tr>
            <tr>
              <th>Nurse</th>
              {filterBedsByRange("ld1", "ld10").map((bed) => (
                <td key={`bed-${bed.id}`}>
                  <select
                    className="bedbody input-field"
                    value={bed.nurseId}
                    onChange={(e) => handleNurseChange(bed.id, e.target.value)}
                  >
                    <option value="">Select Nurse</option>
                    {nurseOptions.map((nurse) => (
                      <option key={nurse.id} value={nurse.id}>
                        {nurse.name}
                      </option>
                    ))}
                  </select>
                </td>
              ))}
            </tr>
            <tr>
              <th>Acuity</th>
              {filterBedsByRange("ld1", "ld10").map((bed) => (
                <td key={`bed-${bed.id}`}>
                  <select
                    className="bedbody input-field"
                    value={bed.acuity}
                    onChange={(e) =>
                      handleAcuityChange(bed.id, bed.nurseId, e.target.value)
                    }
                  >
                    {acuityOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </td>
              ))}
            </tr>
            <tr>
              <th>Notes</th>
              {filterBedsByRange("ld1", "ld10").map((bed) => (
                <td key={`bed-${bed.id}`}>
                  <input
                    className="bedbody input-field"
                    type="text"
                    value={bed.notes}
                    onChange={(e) => handleNotesChange(bed.id, e.target.value)}
                  />
                </td>
              ))}
            </tr>
            {/* <tr>
              <th>EDD</th>
              {filterBedsByRange("ld1", "ld10").map((bed) => (
                <td key={`bed-${bed.id}`}>
                  <input
                    className="bedbody input-field"
                    type="date"
                    value={bed.edd}
                    onChange={(e) => handleEDDChange(bed.id, e.target.value)}
                  />
                </td>
              ))}
            </tr> */}
          </tbody>
        </table>
      </div>
      <div className="container ">
        <div className="mb-3">
          <label for="LdDashboardUserInput" className="form-label stat">
            User input
          </label>
          <textarea
            className="form-control no-outline"
            id="LdDashboardUserInput"
            rows="2"
            value={textAreaValue}
            onChange={handleChange}
            onBlur={handleBlur} // This event triggers when the textarea loses focus
            placeholder="Type something..."
          ></textarea>
        </div>
      </div>
    </div>
  );
};
export default LdRoomAssigned;
