import React, { useState, useEffect } from "react";
import TableHeader from "../ReusableComponents/TableHeader";
import ActionButtons from "../ReusableComponents/ActionButtons";
import NavBar from "../ReusableComponents/NavBar";
import AddNewNurse from "./AddNewNurse";
import { db, auth } from "../../firebase"; // Import auth from firebase
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  deleteUser,
  signOut,
} from "firebase/auth"; // Import createUserWithEmailAndPassword and deleteUser from firebase/auth
import * as XLSX from "xlsx";

import {
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

const AdminDashboard = () => {
  const [modalShowNurse, setModalShowNurse] = useState(false);
  const [nurses, setNurses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingNurse, setEditingNurse] = useState(null);

  // tooltip
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let hideTooltipTimeout;
    if (showTooltip) {
      hideTooltipTimeout = setTimeout(() => {
        setShowTooltip(false);
      }, 2000); // Duration to show the tooltip
    }

    return () => clearTimeout(hideTooltipTimeout);
  }, [showTooltip]);
  // ___________________

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "nurses"),
      (querySnapshot) => {
        const updatedNurses = [];
        querySnapshot.forEach((doc) => {
          updatedNurses.push({ id: doc.id, ...doc.data() });
        });
        setNurses(updatedNurses);
      }
    );
    setLoading(false);

    return () => unsubscribe();
  }, []);

  const handleDeleteNurse = async (id) => {
    try {
      await deleteDoc(doc(db, "nurses", id));
      console.log("Nurse deleted successfully!");
    } catch (error) {
      console.error("Error deleting nurse: ", error);
    }
  };

  const handleChargeRnToggle = async (id, currentValue, nurseEmail) => {
    const auth = getAuth();
    try {
      // Update the chargeRn status in Firestore
      const nurseRef = doc(db, "nurses", id);
      await updateDoc(nurseRef, { chargeRn: !currentValue });

      if (!currentValue) {
        // Attempt to create a new user account for the nurse
        await createUserWithEmailAndPassword(
          auth,
          nurseEmail,
          "defaultPassword"
        )
          .then((userCredential) => {
            // Signed in
            console.log(
              `User account created for email: ${nurseEmail}`,
              userCredential.user
            );
            // It's important to sign out the newly created user right away to maintain the admin session
            signOut(auth);
          })
          .catch((error) => {
            console.error("Error creating user account: ", error.message);
          });
      } else {
        // For deleting a user account, you would need the user to be signed in or use Firebase Admin SDK on the server side
        console.log(
          `User deletion for email ${nurseEmail} requires server-side implementation.`
        );
      }
    } catch (error) {
      console.error("Error updating Charge RN status: ", error);
    }
  };

  const handleEditNurse = (nurse) => {
    setEditingNurse(nurse);
    setModalShowNurse(true);
  };

  const handleExcelFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet);

      setLoading(true);

      let errors = [];

      for (let i = 0; i < data.length; i++) {
        const row = data[i];
        const requiredFields = [
          "userId",
          "userrole",
          "lastName",
          "firstName",
          "middleName",
          "goByName",
          "cellPhone",
          "workPhoneNumber",
          "workEmail",
          "personalEmail",
          "fte",
        ];

        let missingFields = requiredFields.filter((field) => !row[field]);

        if (missingFields.length > 0) {
          errors.push(
            `Row ${i + 2}: Missing required fields: ${missingFields.join(", ")}`
          );
          continue;
        }

        try {
          await addDoc(collection(db, "nurses"), {
            userId: row.userId,
            userrole: row.userrole || "",
            lastName: row.lastName,
            firstName: row.firstName,
            middleName: row.middleName || "",
            goByName: row.goByName || "",
            cellPhone: row.cellPhone || "",
            workPhoneNumber: row.workPhoneNumber || "",
            workEmail: row.workEmail,
            personalEmail: row.personalEmail || "",
            fte: row.fte || "",
            chargeRn: row.chargeRn || false,
          });
        } catch (error) {
          errors.push(`Row ${i + 2}: Error adding document: ${error.message}`);
        }
      }

      setLoading(false);

      if (errors.length > 0) {
        alert(`Errors occurred:\n${errors.join("\n")}`);
      } else {
        alert("All data imported successfully!");
      }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div className="p-4">
      <NavBar />
      <h1 className="pb-4 mt-4 pt-4 mb-2">Hospital Data</h1>
      <div className="row">
        <div className="table-card d-flex flex-column m-4">
          <TableHeader
            title="Nurses"
            onClick={() => setModalShowNurse(true)}
            handleExcelFileChange={handleExcelFileChange}
          />

          <AddNewNurse
            show={modalShowNurse}
            onHide={() => {
              setModalShowNurse(false);
              setEditingNurse(null); // Reset after closing the modal
            }}
            editingNurse={editingNurse}
            onAddOrUpdateNurse={() => {
              setEditingNurse(null); // Reset after submission
            }}
          />

          <div className="mt-4 table-content">
            <table className="table table-borderless">
              <thead>
                <tr className="table-width-column">
                  <th scope="col">#</th>
                  <th scope="col">ID</th>
                  <th scope="col">User Role</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Middle Name</th>
                  <th scope="col">Go By Name</th>
                  <th scope="col">Cell Phone</th>
                  <th scope="col">Work Phone Number</th>
                  <th scope="col">Work Email</th>
                  <th scope="col">Personal Email</th>
                  <th scope="col">FTE</th>
                  <th scope="col">Charge RN</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="13" className="text-center">
                      <Spinner animation="border" />
                    </td>
                  </tr>
                ) : (
                  nurses.map((nurse, index) => (
                    <tr key={nurse.id} className="table-width-column">
                      <th scope="row">{index + 1}</th>
                      <td title={nurse.userId}>{nurse.userId}</td>
                      <td title={nurse.userrole}>{nurse.userrole}</td>
                      <td title={nurse.lastName}>{nurse.lastName}</td>
                      <td title={nurse.firstName}>{nurse.firstName}</td>
                      <td title={nurse.middleName}>{nurse.middleName}</td>
                      <td title={nurse.goByName}>{nurse.goByName}</td>
                      <td title={nurse.cellPhone}>{nurse.cellPhone}</td>
                      <td title={nurse.workPhoneNumber}>
                        {nurse.workPhoneNumber}
                      </td>
                      <td title={nurse.workEmail}>{nurse.workEmail}</td>
                      <td title={nurse.personalEmail}>{nurse.personalEmail}</td>
                      <td title={nurse.fte}>{nurse.fte}</td>
                      <td>
                        <Form>
                          <Form.Switch
                            id={`custom-switch-${index}`}
                            checked={nurse.chargeRn}
                            onChange={() =>
                              handleChargeRnToggle(
                                nurse.id,
                                nurse.chargeRn,
                                nurse.email
                              )
                            }
                          />
                        </Form>
                      </td>
                      <td>
                        <ActionButtons
                          onDelete={() => handleDeleteNurse(nurse.id)}
                          onEdit={() => handleEditNurse(nurse)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
