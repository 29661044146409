// TableHeader component
import React from "react";

const TableHeader = ({ title, onClick, handleExcelFileChange }) => {
  return (
    <div className="row">
      <div className="col-8">
        <h2>{title}</h2>
      </div>
      <div className="col-4 d-flex justify-content-end gap-2">
        {/* Button triggers modal */}
        <button className="btn btn-web" onClick={onClick}>
          Add New
        </button>

        <label htmlFor="excel-file" className="btn btn-web">
          Add Excel Sheet
        </label>
        <input
          type="file"
          className="d-none"
          id="excel-file"
          accept=".xls,.xlsx,.ods"
          onChange={handleExcelFileChange}
        />
      </div>
    </div>
  );
};

export default TableHeader;
