import React from "react";

const ColorStats = ({ shiftNurses }) => {
  let florAquity = 0;
  let rnComp = 0;

  shiftNurses.map((data) => {
    florAquity += Number(data.totalAcuity);
    rnComp += Number(data.rn);
  });

  const staffedBed = (florAquity / rnComp) * 100;

  const getColorClass = (totalAcuity) => {
    const percent = totalAcuity;
    if (percent <= 70) {
      return "orangecol";
    } else if (percent >= 71 && percent <= 89) {
      return "greencol";
    } else if (percent >= 90 && percent <= 99) {
      return "yellowcol";
    } else {
      return "redcol";
    }
  };

  return (
    <div className="p-4">
      <div className="row">
        <div className="col-6 d-flex flex-column justify-content-center">
          <p>
            <span className="stat mr-4">Staffed Beds:</span>{" "}
            <span className={` ${getColorClass(staffedBed)}`}>
              {staffedBed < 0 ? "0.00" : staffedBed.toFixed(2)} %
            </span>
          </p>
          <p>
            <span className="stat mr-4">RN Comp:</span>{" "}
            <span>{rnComp < 0 ? "0.00" : rnComp}</span>
          </p>
          <p>
            <span className="stat mr-4">Floor Acuity:</span>{" "}
            <span>{florAquity < 0 ? "0.00" : florAquity?.toFixed(2)}</span>
          </p>
        </div>

        <div className="col-6">
          <div className="orangecol d-flex justify-content-center">
            <span className="stat mr-4">Orange </span> <span> {`<=70%`}</span>
          </div>
          <div className="greencol d-flex justify-content-center">
            <span className="stat mr-4">Green </span> <span> 70% - 89%</span>
          </div>
          <div className="yellowcol d-flex justify-content-center">
            <span className="stat mr-4">Yellow </span> <span> =90% - 99%</span>
          </div>
          <div className="redcol d-flex justify-content-center">
            <span className="stat mr-4">Red </span> <span> =100%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorStats;
