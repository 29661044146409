import React, { useState, useEffect, useCallback } from "react";
import NavBar from "../ReusableComponents/NavBar";
import ColorStats from "../incharge/ColorStats";
import Discharge from "../incharge/Discharge";
import RoomAssigned from "../incharge/RoomAssigned";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  getDoc,
  doc,
  onSnapshot,
  setDoc, // Import onSnapshot for real-time updates
} from "firebase/firestore";
import LdRoomAssigned from "./LdRoomAssigned";
import LdHospitalBeds from "./LdHospitalBeds";

const LdDashboard = () => {
  const [selectedChargeRn, setSelectedChargeRn] = useState("");
  const [selectedNurseryRn, setSelectedNurseryRn] = useState("");
  const [selectedEdRn1, setSelectedEdRn1] = useState("");
  const [selectedEdRn2, setSelectedEdRn2] = useState("");
  const [selectedEdTreatment, setSelectedEdTreatment] = useState("");
  const [shiftNurses, setShiftNurses] = useState([]);
  let [florAquityValue, setFlorAquityValue] = useState("");

  const handleNurseryChange = async (e) => {
    try {
      const rnName = e.target.value;
      setSelectedNurseryRn(rnName);

      // Update Firestore document
      await updateNurseStatus(rnName, "nurseryRN");
    } catch (error) {
      console.error("Error updating charge RN:", error);
    }
  };

  const handleRnNurseChange = async (e) => {
    try {
      const rnName = e.target.value;
      setSelectedChargeRn(rnName);

      // Update Firestore document
      await updateNurseStatus(rnName, "chargeRN");
    } catch (error) {
      console.error("Error updating charge RN:", error);
    }
  };

  const handleEdRn1 = async (e) => {
    try {
      const rnName = e.target.value;
      setSelectedEdRn1(rnName);

      // Update Firestore document
      await updateNurseStatus(rnName, "obrn1");
    } catch (error) {
      console.error("Error updating charge RN:", error);
    }
  };
  const handleEdRn2 = async (e) => {
    try {
      const rnName = e.target.value;
      setSelectedEdRn2(rnName);

      await updateNurseStatus(rnName, "obrn2");
    } catch (error) {
      console.error("Error updating charge RN:", error);
    }
  };

  const handleEdRnTreatment = async (e) => {
    try {
      const rnName = e.target.value;
      setSelectedEdTreatment(rnName);

      // Update Firestore document
      await updateNurseStatus(rnName, "obtreatment");
    } catch (error) {
      console.error("Error updating charge RN:", error);
    }
  };

  useEffect(() => {
    const fetchShiftNurses = async () => {
      try {
        const shiftNursesCollection = collection(db, "ldNurseShift");
        const unsubscribe = onSnapshot(shiftNursesCollection, (snapshot) => {
          const shiftNursesData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            shiftNursesData.push(...data.nurses);
          });

          // Sort shiftNursesData by date
          shiftNursesData.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB;
          });

          console.log("shiftNursesData", shiftNursesData);
          setShiftNurses(shiftNursesData);

          // Find the nurse with the selectedRnNurse value and update selectedRnNurse
          const selectedRnNurseDoc = shiftNursesData.find(
            (nurse) => nurse.chargeRN === true
          );
          if (selectedRnNurseDoc) {
            setSelectedChargeRn(selectedRnNurseDoc.name);
          }

          // Find the nurse with the selectedNursery value and update selectedNursery
          const selectedNurseryDoc = shiftNursesData.find(
            (nurse) => nurse.nurseryRN === true
          );
          if (selectedNurseryDoc) {
            setSelectedNurseryRn(selectedNurseryDoc.name);
          }

          // Find the nurse with the selectedNursery value and update selectedNursery
          const selectedObRn1Doc = shiftNursesData.find(
            (nurse) => nurse.obrn1 === true
          );
          if (selectedObRn1Doc) {
            setSelectedEdRn1(selectedObRn1Doc.name);
          }
          // Find the nurse with the selectedNursery value and update selectedNursery
          const selectedObRn2Doc = shiftNursesData.find(
            (nurse) => nurse.obrn2 === true
          );
          if (selectedObRn2Doc) {
            setSelectedEdRn2(selectedObRn2Doc.name);
          }
          // Find the nurse with the selectedNursery value and update selectedNursery
          const selectedObRnTreatmentDoc = shiftNursesData.find(
            (nurse) => nurse.obtreatment === true
          );
          if (selectedObRnTreatmentDoc) {
            setSelectedEdTreatment(selectedObRnTreatmentDoc.name);
          }
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching shift nurses:", error);
      }
    };
    fetchShiftNurses();
  }, []);

  const currentDate = new Date(); // Get current date
  const formattedDate = `${currentDate.getDate()} ${currentDate.toLocaleString(
    "default",
    { month: "short" }
  )} ${currentDate.getFullYear()}`; // Format date as DD MM YYYY
  const formattedTime = `${currentDate.getHours()}:${currentDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  const updateNurseStatus = async (nurseName, nurseType) => {
    const shiftDocRef = doc(db, "ldNurseShift", "9HGpGf4u8muT31YlcPw5"); // Reference to your Firestore document

    // Fetch the current shift document
    const shiftDocSnapshot = await getDoc(shiftDocRef);
    console.log(nurseName, nurseType);

    if (shiftDocSnapshot.exists()) {
      const shiftData = shiftDocSnapshot.data();
      let previousNurse = null;

      // Update the nurse data based on the provided nurseName and nurseType
      const updatedNurses = shiftData.nurses.map((nurse) => {
        if (nurse[nurseType] === true) {
          previousNurse = nurse; // Identify the previous nurse assigned to the nurseType
        }
        if (nurse.name === nurseName) {
          return {
            ...nurse,
            [nurseType]: true,
            totalAcuity: (Number(nurse?.totalAcuity) + 0.89).toFixed(2), // Add 0.89 to the totalAcuity for the new nurse
          };
        } else {
          return {
            ...nurse,
            [nurseType]: false,
          };
        }
      });

      // Adjust the acuity for the previous nurse, if necessary
      if (previousNurse && previousNurse.name !== nurseName) {
        const index = updatedNurses.findIndex(
          (nurse) => nurse.name === previousNurse.name
        );
        if (index !== -1) {
          const newAcuityValue =
            Number(updatedNurses[index].totalAcuity) - 0.89;
          updatedNurses[index] = {
            ...updatedNurses[index],
            totalAcuity:
              newAcuityValue < 0 ? "0.00" : newAcuityValue.toFixed(2), // Prevent negative values
          };
        }
      }

      console.log(updatedNurses);
      setShiftNurses(updatedNurses); // Update the local state with the new nurse data

      // Update the Firestore document with the modified nurse data
      await updateDoc(shiftDocRef, { nurses: updatedNurses });
    }
  };

  const getColorClass = (totalAcuity) => {
    const percent = totalAcuity * 100;
    if (percent <= 70) {
      return "orangecol";
    } else if (percent >= 71 && percent <= 89) {
      return "greencol";
    } else if (percent >= 90 && percent <= 99) {
      return "yellowcol";
    } else {
      return "redcol";
    }
  };
  useEffect(() => {
    let florAquity = 0;
    shiftNurses?.map((data) => {
      florAquity += Number(data.totalAcuity);
    });
    setFlorAquityValue(florAquity);
  }, [shiftNurses]);

  return (
    <div className="">
      <NavBar />
      <h1 className=" m-4 pt-4">LD DASHBOARD</h1>

      <div className="p-4 m-4">
        <div className="row">
          <div className="col">
            <p>
              <span className="stat">Date:</span>
              <span className="mx-3">
                {formattedDate} {formattedTime}
              </span>
            </p>
            <p>
              <span className="stat">Charge RN</span>
              <span className=" mx-4">
                <select
                  className="bedbody nurse-input-field"
                  value={selectedChargeRn}
                  onChange={handleRnNurseChange}
                >
                  <option value="">Select Nurse</option>
                  {shiftNurses?.map((nurse, index) => (
                    <option key={index} value={nurse.name}>
                      {nurse.name}
                    </option>
                  ))}
                </select>
              </span>
              {/* <span>0.89</span> */}
            </p>
            <p>
              <span className="stat">Nursery RN</span>
              <span className=" mx-3">
                <select
                  className="bedbody nurse-input-field"
                  value={selectedNurseryRn}
                  onChange={handleNurseryChange}
                >
                  <option value="">Select Nurse</option>
                  {shiftNurses?.map((nurse, index) => (
                    <option key={index} value={nurse.name}>
                      {nurse.name}
                    </option>
                  ))}
                </select>
              </span>
              {/* <span>0.89</span> */}
            </p>
          </div>
          <div className="col">
            <p>
              <span className="stat">OB ED RN #1</span>
              <span className=" mx-3">
                <select
                  className="bedbody nurse-input-field"
                  value={selectedEdRn1}
                  onChange={handleEdRn1}
                >
                  <option value="">Select Nurse</option>
                  {shiftNurses?.map((nurse, index) => (
                    <option key={index} value={nurse.name}>
                      {nurse.name}
                    </option>
                  ))}
                </select>
              </span>
              {/* <span>0.89</span> */}
            </p>
            <p>
              <span className="stat">OB ED RN #2</span>
              <span className=" mx-3">
                <select
                  className="bedbody nurse-input-field"
                  value={selectedEdRn2}
                  onChange={handleEdRn2}
                >
                  <option value="">Select Nurse</option>
                  {shiftNurses?.map((nurse, index) => (
                    <option key={index} value={nurse.name}>
                      {nurse.name}
                    </option>
                  ))}
                </select>
              </span>
              {/* <span>0.89</span> */}
            </p>
            <p>
              <span className="stat">OB Treatment</span>
              <span className=" mx-3">
                <select
                  className="bedbody nurse-input-field"
                  value={selectedEdTreatment}
                  onChange={handleEdRnTreatment}
                >
                  <option value="">Select Nurse</option>
                  {shiftNurses?.map((nurse, index) => (
                    <option key={index} value={nurse.name}>
                      {nurse.name}
                    </option>
                  ))}
                </select>
              </span>
              {/* <span>0.89</span> */}
            </p>
          </div>
          <div className="col"></div>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-md-7 d-flex flex-column mx-2">
          <div className="mb-4 table-card">
            <LdRoomAssigned
              setShiftNurses={setShiftNurses}
              shiftNurses={shiftNurses}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex flex-column mx-2">
          <div className="mb-4 table-card">
            <ColorStats shiftNurses={shiftNurses} />
          </div>
          <div className="table-card">
            <LdHospitalBeds
              setShiftNurses={setShiftNurses}
              shiftNurses={shiftNurses}
            />
          </div>
          {/* <div className="table-card">
            <div className="mt-4 table-responsive">
              <table className="table table-borderless">
                <thead className="">
                  <tr>
                    <th scope="col" className="no-wrap">
                      FLOAT IN (Write In)
                    </th>
                    <th scope="col" colSpan="5">
                      MBU RNs
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {new Array(3).fill(null)?.map((_, index) => {
                    return (
                      <tr key={index}>
                        <td></td>
                        <td>
                          <select
                            className=""
                            // value={selectedMBU}
                            // onChange={handleMbuChange}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <p
                            className={`text-center ${getColorClass(
                              florAquityValue
                            )}`}
                          >
                            0%
                          </p>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-4 table-responsive">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col" className="no-wrap">
                      FLOAT OUT
                    </th>
                    <th scope="col" colSpan="5"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {new Array(3).fill(null)?.map((_, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <select
                            className=""
                            // value={floatOut}
                            // onChange={handleFloatOutChange}
                          >
                            <option value="">Select</option>
                            {shiftNurses?.map((res, index) => {
                              return (
                                <option key={index} value={res.name}>
                                  {res.name}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-4 table-responsive">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <td>
                      <select
                        className=""
                        // value={overStaff}
                        // onChange={handleOverStaffChange}
                      >
                        <option value="">OVERSTAFF</option>
                        {shiftNurses?.map((res, index) => {
                          return (
                            <option key={index} value={res.name}>
                              {res.name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <th scope="col" colSpan="5"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {new Array(2).fill(null)?.map((_, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <select
                            className=""
                            // value={overStaff}
                            // onChange={handleOverStaffChange}
                          >
                            <option value="">Select</option>
                            {shiftNurses?.map((res, index) => {
                              return (
                                <option key={index} value={res.name}>
                                  {res.name}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LdDashboard;
